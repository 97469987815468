@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);
@import url(https://unpkg.com/leaflet@1.5.1/dist/leaflet.css);
body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', Arial, 'Helvetica Neue', sans-serif;
  line-height: 1.2em; }

html, body, .main-content{
  min-height:70.8vh;
}
.main-content > .content{
  min-height:67.6vh;
}

@media screen and (min-height: 375px) {
  .main-content > .content {
    padding: 16px;
  }
}
h1, h2 {
  color: #707070; }

h1 {
  font-size: 32px;
  line-height: 1.6em; }

h2 {
  font-size: 20px;
  line-height: 1.3em; }

input, textarea {
  font-family: 'Open Sans', Arial, 'Helvetica Neue', sans-serif; }

.meer {
  display: block;
  margin: 5px auto 30px;
  padding: 5px 0;
  text-align: center;
  width: 170px;
  border-bottom: 2px solid #00AACC;
  color: #707070;
  font-size: 20px;
  font-weight: bold;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out; }
  .meer:hover {
    color: #00AACC; }

.relative {
  position: relative; }
  .relative.searchfilters {
    height: 200px; }
    .relative.searchfilters .fixed {
      position: fixed;
      top: 100px;
      width: calc(100% - 30px);
      z-index: 998;
      background-color: #FFFFFF; }

a {
  text-decoration: none; }
  a.primary {
    border-radius: 8px;
    color: #FFFFFF !important;
    border: 2px solid #00AACC;
    background-color: #00AACC; }
    a.primary:hover {
      -webkit-transition: all 200ms ease-in-out;
      transition: all 200ms ease-in-out;
      color: #00AACC !important;
      background-color: transparent; }

.dropdown-menu-list {
  display: block;
  font-weight: 700;
  font-family: 'Open Sans', Arial, 'Helvetica Neue', sans-serif;
  font-size: 16px;
  line-height: 22px;
  text-decoration: none;
  border-radius: 8px;
  color: #707070 !important;
  border: 2px solid #707070;
  background-color: #FFFFFF;
  white-space: nowrap;
  position: absolute;
}

button.primary {
  display: block;
  font-weight: 700;
  font-family: 'Open Sans', Arial, 'Helvetica Neue', sans-serif;
  font-size: 16px;
  padding: 5px 10px;
  margin: 0 5px;
  line-height: 22px;
  height: 37px;
  text-decoration: none;
  border-radius: 8px;
  color: #00AACC !important;
  border: 2px solid #00AACC;
  background-color: #FFFFFF; }
  button.primary:hover {
    cursor: pointer;
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
    color: #00AACC !important;
    background-color: transparent; }

.back {
  display: block;
  font-weight: 700;
  color: #707070;
  padding: 5px 10px;
  margin: 0 5px;
  line-height: 22px;
  height: 22px;
  display:inline-block; }
  back:hover {
    cursor: pointer;
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
    color: #00AACC !important;
    background-color: transparent; }

.card-container {
  display: grid;
  padding: 10px 0;
  grid-column-gap: 15px;
  grid-row-gap: 15px; }
  .card-container .card-item {
    position: relative;
    max-height: 500px;
    padding: 15px;
    overflow: hidden; }

  .issuer {
    background-color: #E2F4F8;
    color: #919798;
    border-radius: 8px;
  }

  .issuer-data {
    height: 125px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
  }

  .table--issuers-info {
    display: flex;
    flex-direction: column;
  }

  .row--issuers-info {
    display: flex;
    justify-content: space-between;
    height: 24px;
  }

  .button--issuer-accept {
    width: 120px;
    align-self: center;
    background-color: #F7B335;
    border: none;
    border-radius: 8px;
    color: white;
    font-family: 'Open Sans', Arial, 'Helvetica Neue', sans-serif;
    font-size: 16px;
    font-weight: 700;
    padding: 5px 10px;
    height: 40px;
  }

  .button--issuer-accept:hover, button[disabled] {
    background-color: #cccccc;
  }

  .button--issuer-accept:hover:enabled {
    color: #F7B335;
    border: solid 2px #F7B335;
    background-color: transparent;
    -webkit-transition: all 258ms ease-in-out;
    transition: all 258ms ease-in-out;
  }

@media screen and (min-width: 415px) {
  .card-container {
    grid-template-columns: auto; } }

@media screen and (min-width: 650px) {
  .card-container {
    grid-template-columns: auto auto; }
    .card-container.opportunities {
      grid-template-columns: auto; }
    .card-container.created-opportunities {
      grid-template-columns: auto; } }

@media screen and (min-width: 960px) {
  .card-container {
    grid-template-columns: auto auto auto; }
    .card-container.opportunities {
      grid-template-columns: auto auto; }
    .card-container.created-opportunities {
      grid-template-columns: auto auto; } }
@media screen and (min-width: 1600px) {
  .card-container {
    grid-template-columns: auto auto auto; }
    .card-container.opportunities {
      grid-template-columns: auto auto auto; }
    .card-container.created-opportunities {
      grid-template-columns: auto auto auto; } }

#recente-ervaringen h1 {
  text-align: center; }

#recente-ervaringen .ervaring {
  background-size: cover;
  border-radius: 15px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  height: 400px;
  border: none;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out; }
  #recente-ervaringen .ervaring .data {
    position: absolute;
    bottom: 30px;
    left: 0;
    padding: 5px 10px;
    background: rgba(0, 0, 0, 0.35);
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out; }
    #recente-ervaringen .ervaring .data h2 {
      color: #FFFFFF; }
    #recente-ervaringen .ervaring .data small {
      color: #FFFFFF; }
  #recente-ervaringen .ervaring:hover {
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.26);
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
    #recente-ervaringen .ervaring:hover .data {
      -webkit-transform: translateY(-120px);
              transform: translateY(-120px); }

#create_opportunity form {
  width: 100%; }
  #create_opportunity form .form-group {
    width: 100%;
    padding-bottom: 10px; }
    #create_opportunity form .form-group label {
      display: block;
      width: 100%;
      padding: 8px 0 0;
      font-size: 18px;
      line-height: 24px;
      text-transform: uppercase;
      font-weight: bold;
      color: #707070;
      padding-bottom: 2px; 
    }
    #create_opportunity form .form-group small {
      /* font-style: italic; */
      color: #707070;
      visibility: hidden;
    }
    #create_opportunity form .form-group .input-info-wrapper{
      padding-left: 10px;
      padding-right: 10px;
      max-height: 18px;
    }
    #create_opportunity form .form-group:hover small{
      visibility: visible;
    }
    #create_opportunity form .form-group:hover .input-info-wrapper{
      max-height: 200px;
    }
    #create_opportunity form .form-group a {
      /* text-decoration: underline; */
      /* color: #707070;  */
    }
    #create_opportunity form .form-group input,
    #create_opportunity form .form-group textarea {
      display: block;
      padding: 8px 16px;
      margin-bottom: 8px;
      width: calc(100% - 32px - 2px);
      border: 1px solid #ddd;
      border-radius: 8px;
      outline: none; }
      #create_opportunity form .form-group input:focus,
      #create_opportunity form .form-group textarea:focus {
        border: 1px solid turquoise; }
@-webkit-keyframes bounce {
  0%, 20%, 60%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  80% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}
@keyframes bounce {
  0%, 20%, 60%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  80% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}
#opportunities {
  display: flex;
  flex-direction: column-reverse;  }

  @media screen and (min-width: 1024px) {
    #opportunities {
      flex-direction: row;
    }
  }
  #opportunities h1.uitgelicht {
    text-align: center; }
  #opportunities .content-left {
    width: 100%; 
    margin-top: 50px}

    @media screen and (min-width: 1024px) {
      #opportunities .content-left {
        width: 60%;
        margin-top: 0px;
      }
    }
  #opportunities .content-right {
    /* height: calc(100vh - 209px); */
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }

    @media screen and (min-width: 1024px) {
      #opportunities .content-right {
        width: 40%;
        margin-right: -15px;
        width: calc(40% + 15px);
        padding: 0px;
        position: -webkit-sticky;
        position: sticky;
        top: 6.5rem;
      }

    }
  #opportunities .card-item.opportunity {
    border-radius: 15px;
    border: 2px solid #F2F0ED;
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
    background-color: white;}
    @media screen and (min-width: 1080px){
      #opportunities .card-item.opportunity {
        max-width: 26.5vw;}
      }
    #opportunities .card-item.opportunity:hover {
      box-shadow: 0 0px 10px rgba(0, 0, 0, 0.26);
      -webkit-transform: scale(1.03);
              transform: scale(1.03); }
    #opportunities .card-item.opportunity:hover img.photo{
      -webkit-transform: scale(1.10);
              transform: scale(1.10);
      -webkit-transition: -webkit-transform .2s;
      transition: -webkit-transform .2s;
      transition: transform .2s;
      transition: transform .2s, -webkit-transform .2s; }
    #opportunities .card-item.opportunity:hover img.badge{
      -webkit-animation: bounce 1s;
              animation: bounce 1s; }
    #opportunities .card-item.opportunity.digitale-geletterdheid:hover {
      border-color: #00AACC; }
    #opportunities .card-item.opportunity.duurzaamheid:hover {
      border-color: #BBCC00; }
    #opportunities .card-item.opportunity.ons:hover {
      border-color: #A8A8A8; }
    #opportunities .card-item.opportunity.wbs:hover {
      border-color: #EE9900; }
    #opportunities .card-item.opportunity.ond:hover {
      border-color: #CC0077; }
    #opportunities .card-item.opportunity > img.photo {
      margin: -15px -15px 0 -15px;
      width: calc(100% + 30px); }
    #opportunities .card-item.opportunity img.badge {
      position: absolute;
      width: 70px;
      height: auto;
      top: -75px;
      right: 0; }
    #opportunities .card-item.opportunity h2 {
      color: #00AACC; }
    #opportunities .card-item.opportunity .meta-data small {
      display: block;
      color: #aeaeae;
      font-size: 12px; }
    #opportunities .card-item.opportunity p {
      color: #707070;
      font-size: 14px; }

  .opportunity--info {
    background-color: #E2F4F8;
    color: #707070;
    border-radius: 8px;
    padding: 20px;
  }

  .title-opportunity-info {
    height: 55px;
  }

  .text--opportunity-description {
    height: 150px;
  }

  .button-opportunity-accept {
    width: 120px;
    align-self: center;
    background-color: #F7B335;
    border: none;
    border-radius: 8px;
    color: white;
    font-family: 'Open Sans', Arial, 'Helvetica Neue', sans-serif;
    font-size: 16px;
    font-weight: 700;
    padding: 5px 10px;
    height: 40px;
    margin-top: 20px;
  }


  .button-opportunity-accept:hover:enabled {
    color: #F7B335;
    border: solid 2px #F7B335;
    background-color: transparent;
    -webkit-transition: all 258ms ease-in-out;
    transition: all 258ms ease-in-out;
  }

  .button-opportunity-accept:disabled, button[disabled] {
    background-color: #cccccc;
    color: #666666;
  }

#created-opportunities {
  display: flex;
  /* flex-direction: row; */}
  #created-opportunities h1.uitgelicht {
    text-align: center; }
  #created-opportunities .content-left {
    width: 100%; }
  #created-opportunities .content-right {
    height: calc(100vh - 209px);
    width: 0%;
    margin-right: -15px;
    width: calc(40% + 15px); }
  #created-opportunities .card-item.opportunity {
    border-radius: 15px;
    border: 2px solid #F2F0ED;
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out; 
    width: 500px;
    background-color: white;}
    #created-opportunities .card-item.opportunity:hover {
      box-shadow: 0 0px 10px rgba(0, 0, 0, 0.26); }
    #created-opportunities .card-item.opportunity.digitale-geletterdheid:hover {
      border-color: #00AACC; }
    #created-opportunities .card-item.opportunity.duurzaamheid:hover {
      border-color: #BBCC00; }
    #created-opportunities .card-item.opportunity.ons:hover {
      border-color: #A8A8A8; }
    #created-opportunities .card-item.opportunity.wbs:hover {
      border-color: #EE9900; }
    #created-opportunities .card-item.opportunity.ond:hover {
      border-color: #CC0077; }
    #created-opportunities .card-item.opportunity > img.photo {
      margin: -15px -15px 0 -15px;
      width: calc(100% + 30px); }
    #created-opportunities .card-item.opportunity img.badge {
      position: absolute;
      width: 70px;
      height: auto;
      top: -60px;
      right: 0; }
    #created-opportunities .card-item.opportunity h2 {
      color: #00AACC; }
    #created-opportunities .card-item.opportunity .meta-data small {
      display: block;
      color: #aeaeae;
      font-size: 12px; }
    #created-opportunities .card-item.opportunity p {
      color: #707070;
      font-size: 14px; }

form .search-wrapper {
  width: 100%;
  min-height: 50px;
  margin: 0 auto;
  position: relative; }
  form .search-wrapper.frontpage {
    max-width: 800px; }
    form .search-wrapper.frontpage i {
      top: 28px;
      left: 30px; }
    form .search-wrapper.frontpage input {
      width: calc(100% - 80px);
      border-radius: 8px;
      max-width: 800px;
      padding: 15px 20px 15px 60px; }
    form .search-wrapper.frontpage > button {
      top: 10px;
      right: 10px;
      height: 48px; }
  form .search-wrapper.opportunities i {
    font-size: 14px;
    top: 22px;
    left: 22px; }
  form .search-wrapper.opportunities input {
    width: calc(100% - 71px);
    border-radius: 8px 8px 0 0;
    font-size: 16px;
    border: 1px solid #E8E8E8;
    padding: 10px 20px 10px 50px; }
  form .search-wrapper.opportunities > .filters {
    border: 1px solid #E8E8E8;
    margin-top: -1px;
    border-radius: 0 0 6px 6px; }
    form .search-wrapper.opportunities > .filters > button {
      padding: 0;
      height: 40px;
      width: 25%;
      border: none;
      color: #707070;
      border-right: 1px solid #E8E8E8;
      outline: none;
      -webkit-transition: all 200ms ease-in-out;
      transition: all 200ms ease-in-out; }
      form .search-wrapper.opportunities > .filters > button:first-child {
        margin-left: 1px;
        width: calc(25% - 1px);
        border-radius: 0 0 0 6px; }
      form .search-wrapper.opportunities > .filters > button:last-child {
        border-right: none;
        border-radius: 0 0 6px 0; }
      form .search-wrapper.opportunities > .filters > button:hover {
        background-color: #E8E8E8;
        cursor: pointer; }
  form .search-wrapper i {
    position: absolute;
    color: #aeaeae; }
  form .search-wrapper input {
    font-size: 18px;
    line-height: 2em;
    color: #252525;
    border: 1px solid #FFFFFF;
    outline: none;
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out; }
    form .search-wrapper input:focus, form .search-wrapper input:active {
      border-color: #00AACC;
      box-shadow: 0 0px 10px rgba(0, 0, 0, 0.26); }
    form .search-wrapper input::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #aeaeae; }
  form .search-wrapper > button {
    position: absolute;
    min-width: 100px;
    border-radius: 6px;
    border: 0;
    background-color: #00AACC;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer; }
    form .search-wrapper > button:hover {
      background-color: #0095b3; }

nav {
  height: 100px; }
  nav .nav-wrapper {
    position: fixed;
    width: 100vw;
    height: 100px;
    display: flex;
    flex-direction: row;
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.26);
    background-color: #FFFFFF;
    z-index: 999; }
  nav .logo {
    height: 100%;
    width: 270px;
    padding-left: 30px; 
  }

  @media screen and (min-width:320px) {
    nav .logo {
      padding-left: 16px;
    }
  }
    nav .logo img#gs-logo {
      height: 30px;
      padding: 35px 0; }
  nav .nav {
    width: 100%; }
    nav .nav ul#gs-nav {
      display: flex;
      width: auto;
      position: absolute;
      right: 30px;
      margin-top: 65px;
      background: white;
      /* border: 0px; */
      /* border-radius: 8px; */
      
      /* margin-right: -20px;
      /* padding: 22px 0; */
      overflow: hidden; 
    }
      /* nav .nav ul li{
        background: white;
        border: 2px solid #00AACC;
      } */
      @media screen and (max-width: 1080px){
        nav .dropdown{
          display: none; 
        }
        nav .nav ul#gs-nav {
          /* max-height: none; */
          padding-left: 0px; 
          margin: 0;
          margin-top: 100px;
          padding: 0;
          border-top: 0;
          position: fixed;
          top: 0;
          right: 0;
          width: 100vw;
        }
      }
      @media screen and (min-width: 320px) {
        nav .logo img#gs-logo {
          height: 45px;
          padding: 35px 0; }
        nav .nav ul#gs-nav {
          flex-direction: column;
          height: 100vh;
          position: absolute;
        } 
      }
      @media screen and (min-width: 1080px) {
        nav .dropdown_mobile{
          display: none; 
        }
        nav .logo img#gs-logo {
          /* height: 45px; */
          padding: 35px 0; }
        nav .nav ul#gs-nav {
          margin-top: 0px;
          overflow: visible;
          flex-direction: row;
          height: 100px;
         } }
      nav .nav ul#gs-nav li {
        list-style: none;
        position: relative;
        width: auto;
        margin: 15px 0px 15px 0px; }
        nav .nav ul#gs-nav li.nav_item .active {
          border-bottom: 2px solid #00AACC; }
        nav .nav ul#gs-nav li.nav_item.language {
          font-size: 14px;}
        nav .nav ul#gs-nav li a {
          display: block;
          font-weight: 700;
          color: #707070;
          padding: 5px 10px;
          margin: 0 5px;
          line-height: 22px;
          height: 22px; }
          nav .nav ul#gs-nav li a:hover {
            -webkit-transition: all 258ms ease-in-out;
            transition: all 258ms ease-in-out;
            color: #252525; }

/* hamburger */
.nav .menu {
  clear: both;
  max-height: 0;
  -webkit-transition: max-height .1s ease-out;
  transition: max-height .1s ease-out;
}
.nav .menu-icon {
  cursor: pointer;
  display: inline-block;
  float: right;
  padding: 28px 20px;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.nav .menu-icon .navicon {
  background: #333;
  display: block;
  height: 2px;
  position: relative;
  -webkit-transition: background .2s ease-out;
  transition: background .2s ease-out;
  width: 18px;
  margin-right: 10px;
  margin-top: 20px
}
.nav .menu-icon .navicon:before,
.nav .menu-icon .navicon:after {
  background: #333;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out;
  width: 100%;
}
.nav .menu-icon .navicon:before {
  top: 5px;
}
.nav .menu-icon .navicon:after {
  top: -5px;
}
.nav .menu-btn {
  display: none;
}
.nav .menu-btn:checked ~ .menu {
  max-height: 240px;
}
.nav .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}
.nav .menu-btn:checked ~ .menu-icon .navicon:before {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.nav .menu-btn:checked ~ .menu-icon .navicon:after {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.nav .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.nav .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}
@media (max-width: 1080px) {
  .nav .menu-btn:checked ~ ul#gs-nav {
    border-top: 1px solid #ddd;
    max-height: none;
  }
}
@media (min-width: 1080px) {
  .nav li {
    float: left;
  }
  .nav li {
    padding: 20px 0px;
  }
  .nav .menu {
    clear: none;
    float: right;
    max-height: none;
  }
  .nav .menu-icon {
    display: none;
  }
  .nav .menu-btn:checked ~ ul#gs-nav {
    border-top: 0px;
  }
}

.container {
  position: relative;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto; }
  .container.full-height {
    height: calc(100vh - 100px); }

.content {
  position: relative;
  padding: 15px; }

.opportunities-content {
  background-color: rgb(248, 248, 248);
}

.profile-page body{
  margin: 0;
  background-color: #ececec;
  font-family: Roboto, Calibri;
}
.profile-page img{
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
/*box-shadow: 0 1px 15px rgba(0,0,0,0.55);*/
}
.profile-page .profile{
  background-color: #FFF;
  width: 80%;
  height: 500px;
  margin: 10vh auto;
  position: relative;
  border-radius: 5px;
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

@media screen and (max-width: 768px) {
  .profile-page .profile {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
    grid-template-areas: "name"
    "info";
  }
}
.profile-page .half{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .profile-page .half {
    width: 100%;
  }
}

.profile-page .left{
  position: absolute;
  left: 0;
  color: #00AACC;
  background-color: #FFF;
  border-radius: 5px 0 0 5px;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .profile-page .left {
    grid-area: name;
  }
}
.profile-page .right{
  position: absolute;
  right: 0;
  color: #FFF;
  background-color: #00AACC;
  border-radius: 0 5px 5px 0;
  align-items: flex-start;
}

@media screen and (max-width: 768px) {
  .profile-page .right {
    grid-area: info;
    right: 1;
  }
}
.profile-page .name{
  margin-top: 1em;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}
.profile-page .info{
  width: 90%;
  margin: auto;
  flex-direction: column;
  display: flex;
}
.profile-page .title{
  font-size: 1.1rem;
  font-weight: 600;
  letter-spacing: 0.1px;
}
.profile-page .desc{
  font-size: 0.9rem;
  font-weight: 100;
  letter-spacing: 0.3px;
  margin: 0 1em 1em;
}

footer {
  width: 100%;
  height: 175px;
  background-color: #F2F0ED; }
  @media screen and (min-width:320px) {
    footer {
      height: 293px;
    }
  }
  footer ul {
    display: flex;
    list-style: none;
    margin-bottom: 0px;
    padding-bottom: 30px;
    padding-left: 0;
    line-height: 2em; }
    @media screen and (min-width: 320px) {
      footer ul {
        flex-direction: column; } }
    @media screen and (min-width: 768px) {
      footer ul {
        flex-direction: row; } }
    footer ul.first {
      border-bottom: 1px solid #aeaeae; }
    footer ul.bottom {
      padding: 0; }
      @media screen and (min-width: 320px) {
        footer ul.bottom {
          position: relative; } }
      @media screen and (min-width: 960px) {
        footer ul.bottom {
          position: absolute;
          right: 15px; } }
      footer ul.bottom li {
        width: inherit; }
        footer ul.bottom li a {
          padding: 3px 8px;
          font-weight: normal;
          font-size: 12px; }
    footer ul li {
      padding: 0 30px 0 0; }
      footer ul li a {
        color: #707070;
        font-weight: bold;
        font-size: 14px; }
        footer ul li a:hover {
          -webkit-transition: all 258ms ease-in-out;
          transition: all 258ms ease-in-out;
          color: #252525; }
  footer .footer_bottom {
    display: flex; }
    @media screen and (min-width: 320px) {
      footer .footer_bottom {
        flex-direction: column; } }
    @media screen and (min-width: 960px) {
      footer .footer_bottom {
        flex-direction: row; } }
    footer .footer_bottom img {
      width: 200px;
      padding-top: 20px; 
    }
    footer .footer_bottom div {
      width: 100%; }

#startpage {
  position: relative;
  width: 100% !important;
  height: 90vh !important;
  min-height: 550px !important;
  max-height: 900px !important;
  overflow: hidden !important;
  contain: strict !important;
  text-shadow: 0 10px 10px rgba(0, 0, 0, 0.43); }
  #startpage .eyecather-wrapper {
    width: 100% !important;
    position: absolute !important;
    height: 90vh !important;
    min-height: 550px !important;
    max-height: 900px !important;
    overflow: hidden !important;
    contain: strict !important;
    /* background-color: rgba(28, 111, 144, 0.5);  */
    background: webkit-linear-gradient(to bottom, #c4ecff33, rgba(20, 1, 5, 0.50));
    background: -webkit-gradient(linear, left top, left bottom, from(#c4ecff33), to(rgba(20, 1, 5, 0.50)));
    background: -webkit-linear-gradient(top, #c4ecff33, rgba(20, 1, 5, 0.50));
    background: linear-gradient(to bottom, #c4ecff33, rgba(20, 1, 5, 0.50));
  }
    #startpage .eyecather-wrapper img {
      position: absolute;
      height: 100%;
      width: auto;
      z-index: -1;
      filter: blur(10px);
      -webkit-filter: blur(10px);
      -webkit-transition: all 1000ms;
      transition: all 1000ms;
      /* opacity: .8;  */
    }
  #startpage .content {
    text-align: center;
    /* margin: calc(30vh - 30px) 0; */
    width: calc(100% - 30px) !important;
    min-height: 300px !important;
    max-height: 840px !important;
    overflow: hidden !important;
    /* contain: strict !important; */
   }
    #startpage .content h1, #startpage .content h2, #startpage .content p {
      color: #FFFFFF;
      /* mix-blend-mode: difference; */
      /* text-shadow: 0 0px 10px rgba(0, 0, 0, 0.26); */
    }
    #startpage .content h1 {
      margin: 0;
      margin-top: 10vh;
      font-size: 6vh; }
    #startpage .content h2 {
      margin-top: 0;
      /* margin-bottom: 5vh; */
      font-size: 4vh; }
    #startpage .content p{
      font-size: 2.3vh;
      line-height: 2.5vh;
    }
    #startpage .text{
      /* background-color: rgba(0, 16, 20, 0.473); */
      display: inline-block;
      max-width: 45vw;
      /* padding: 10px; */
      margin-top: 0px;
    }
    /* @media screen and (min-width: 960px) {
      #startpage .content h1 {
        font-size: 50; }
      #startpage .content h2 {
        margin-top: 0; }
      #startpage .content p{
        display: inline-block;
        max-width: 40vw;
    } */

#new > div > div {
  position: absolute;
  top: 80px;
  left: 15px; }
  #new > div > div h2 {
    color: #FFFFFF;
    text-shadow: 0 0px 10px rgba(0, 0, 0, 0.26); }
  #new > div > div a {
    display: inline-block;
    background: white;
    color: #707070;
    font-weight: bold;
    padding: 10px 15px;
    border: 1px solid #FFFFFF;
    border-radius: 8px; }
    #new > div > div a:hover {
      -webkit-transition: all 200ms ease-in-out;
      transition: all 200ms ease-in-out;
      border-color: #00AACC;
      color: #00AACC; }

#download {
  position: relative;
  background: #00AACC;
  padding: 15px 30px;
  margin-top: 80px; }
  #download .content {
    display: flex;
    flex-direction: row; }
    #download .content .left {
      width: 30%;
      overflow: hidden; }
      #download .content .left img {
        position: absolute;
        width: 250px;
        bottom: -15px;
        left: 0; }
    #download .content .right {
      width: 70%; }
      #download .content .right h1 {
        color: #FFFFFF; }
      #download .content .right p {
        color: white; }
      #download .content .right img {
        width: 150px; }
.l-container ul {
  padding: 0;
  list-style-type: none;
}

.l-container img {
  width: 60px;
  margin: 5px 5px 5px 5px;
  border-radius: 5px;
}

/* LAYOUT ==================== */
.l-nav {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100px;
  background-color: #242D33;
}
.l-nav__container, .l-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 980px;
  margin: 0 auto;
}
@media screen and (max-width: 1050px) {
  .l-nav__container, .l-container {
    width: 95%;
  }
}
.l-nav__button {
  color: #e0e0e0;
  background-color: #3392CC;
  padding: 10px;
  text-align: center;
  width: 30%;
  border-radius: 5px;
}
.l-nav__button:hover {
  background-color: #2e83b8;
  color: white;
}

.l-container {
  display: block;
  /* padding-top: 115px; */
}

/* MODULE ==================== */
.list {
  display: flex;
  background-color: white;
  margin: 10px;
  padding: 5px;
  border-radius: 5px;
  position: relative;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.list:hover {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25), 0 5px 8px rgba(0, 0, 0, 0.22);
  cursor: pointer;
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}
.list:hover img.pin{
  -webkit-animation: bounce 1s;
          animation: bounce 1s;
}
.list__opportunity_title {
  display: flex;
  /* flex-grow: 0.7; */
  overflow: hidden;
  width:30%;
  text-align: left;
  justify-content: flex-start;
}
.list__opportunity_data {
  display: flex;
  text-align: left;
  justify-content: flex-end;
  width:60%;
  overflow: hidden;
  white-space: nowrap;
}
.list__opportunity_data img {
  width: 100px;
}
.list__opportunity_data .list__label{
  padding: 10px;
  /* font-size: 15px; */
}
@media screen and (max-width: 400px) {
  .list__opportunity_data img {
    width: 75px;
  }
}
.list__label {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
}
.list__label__title {
  align-items: flex-start;
}
.list__label--header {
  color: #00AACC;
  
}
.list__label--value {
  font-size: 1.3em;
  color: #707070;
  /* overflow: hidden; */
  float: left;
}
@media screen and (max-width: 650px) {
  .list {
    flex-direction: column;
  }
  .list__opportunity_title {
    justify-content: center;
    margin: 10px;
  }
  .list__label {
    width: 170px;
  }
  .list__opportunity_data {
    justify-content: center;
  }
}

/* STATE ==================== */
.is-list-selected {
  background: #cb2d3e;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #cb2d3e, #ef473a);
  background: -webkit-gradient(linear, left top, right top, from(#cb2d3e), to(#ef473a));
  background: -webkit-linear-gradient(left, #cb2d3e, #ef473a);
  background: linear-gradient(to right, #cb2d3e, #ef473a);
  color: white;
}
ul li.list:hover .edit {
	visibility: visible;
}
ul li.list:hover .copy {
	visibility: visible;
}
ul li.list:hover .delete {
	visibility: visible;
}
/* .icon-option{
  padding-left: 15px;
} */
.icon-container{
  color: #00AACC;
  float: right;
  padding-top: 7px;	
	padding-right: 7px;
  visibility: hidden;
  position: relative;
  z-index: 1;
}
.icon-container i{
  margin:2px;
}
.icon-container:hover{
  color: rgb(0, 133, 160);
}

.filler{
  flex-grow: 1;
}

.icon-options{
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}
.icon-options a{
  width: 34%;
}

.icon-options button {
  background: transparent;
  border: none;
}

.map-container{
  overflow: hidden;
  object-fit: cover;
}

.opportunity-detail{
  background: rgb(248, 248, 248);
  min-height: 70.8vh;
  /* font-family:lato; */
}

.opportunity-detail h3{
  font-weight:700;
}

.opportunity-detail h1{
    position: absolute;
    top: 0;
    font-size: 4vw;
    color: #fff;
    text-align: center;
    width: calc(100% - 20px);
    /* text-shadow: -1px 0 #707070, 0 1px #707070, 0px 0 #707070, 0 -0px #707070; */
    text-shadow: 0 0px 10px rgba(0, 0, 0, 0.26);
    z-index: 1;
}

.opportunity-detail .overlay{
  position:absolute;
  top: 0px;
  width:100%;
  height:150px;
/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.65+0,0+100;Neutral+Density */
  /* background: -moz-linear-gradient(top, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%); FF3.6-15 */
  /* background: -webkit-linear-gradient(top, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%); Chrome10-25,Safari5.1-6 */
  /* background: linear-gradient(to bottom, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%); W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  /* filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); IE6-9 */
  background-size:cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
    /* background-size: auto; */
  z-index:1;
}

.opportunity-detail .titlehead-wrapper{
  width:calc(100% - 20px);
  height:250px;
  background-size:cover;
  padding: 10px;
  background-position: center center;
  position: relative;
}
.opportunity-detail .titlehead{
  /* background-color: rgba(0, 0, 0, 0.15); */
  background: -webkit-gradient(linear,left top, left bottom,from(rgba(0,0,0,.01)),to(rgba(0, 0, 0, 0.25)));
  background: -webkit-linear-gradient(top,rgba(0,0,0,.01),rgba(0, 0, 0, 0.25));
  background: linear-gradient(180deg,rgba(0,0,0,.01),rgba(0, 0, 0, 0.25));
  position: absolute;
  padding: 10px;
  top: 0;
  left: 0;
  width: calc(100% - 20px);
  height: 250px;
}

.opportunity-detail #page.opportunity-container{
  position: relative;
  color:#707070;
  background: #fff;
  margin: -40px 80px 0px 80px;
  padding: 40px;
/* -webkit-box-shadow: 0px 3px 10px 0px rgba(50, 50, 50, 0.5);
  -moz-box-shadow: 0px 3px 10px 0px rgba(50, 50, 50, 0.5); */
  box-shadow: 0px 3px 10px 0px rgba(50, 50, 50, 0.5);
}

.opportunity-info-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-prim {
  margin-top: 2.5rem;
  background-color: #F7B335;
  border: none;
  border-radius: 30px;
  color: white;
  font-family: 'Open Sans', Arial, 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-weight: 700;
  padding: 0.5rem 2rem;
  box-shadow: 0px 2px 10px 3px rgba(0,0,0,0.15);
  cursor: pointer;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

.button-prim:hover {
  background-color: #D49125;
}

.opportunity-detail #menu-button{
  position:fixed;
  left:20px;
  top:20px;
  width:30px;
  height:30px;
  z-index:100;
  background:transparent;
  border:0px; 
  padding:0px;
}

.opportunity-detail #menu-button span{
  width:28px;
  height:4px;
  margin:6px 0;
  border-radius:2px;
  background:#fff;
  display:block;
}

.opportunity-detail #menu{
  position:fixed;
  left:0px;
  top:0px;
  right:0px;
  bottom:0px;
  width:100%;
  height:100%;
  border-radius:50%;
  background:rgba(0,0,0,.6);
  -webkit-transform:scale(0);
          transform:scale(0);
  z-index:99;
   opacity:0;
     -webkit-transition: all cubic-bezier(.32, -0.6, .68, 1.6) .3s;
    transition: all cubic-bezier(.32, -0.6, .68, 1.6) .3s; 
}

.opportunity-detail .showmenu{
    -webkit-transform:scale(1) !important;
            transform:scale(1) !important;
    border-radius:0% !important;
  opacity:1 !important;
     -webkit-transition: all cubic-bezier(.32, -0.6, .68, 1.6) .3s;
    transition: all cubic-bezier(.32, -0.6, .68, 1.6) .3s; 
} 


.opportunity-detail #page{
  border-radius: 40px;
    webkit-transition: all ease-out .3s;
    -webkit-transition: all ease-out .3s;
    transition: all ease-out .3s; 
  
}

.opportunity-detail ul{
  width:100%;
  text-align:center;
  max-width:400px;
  color: #fff;
  list-style:none;
  margin: 0 auto;
  padding :0px !important;
  position:relative;
  top:50%;
  -webkit-transform:translateY(-50%);
          transform:translateY(-50%);
  ;
}
.opportunity-detail ul li{
  width:100%;
  margin:0; padding:10px;
  font-size: 2em;
}

.opportunity-detail .btn:focus {
  outline: none;
}
.opportunity-detail .btn:focus,.btn:active:focus,.btn.active:focus,
.opportunity-detail .btn.focus,.btn:active.focus,.btn.active.focus {
    outline: none;
}

.infobox{
  border-style: solid;
  border-width: 2px;
  border-radius: 25px;
  padding: 0px 20px 0px 20px;
  margin: 0px 20px 0px 20px;
  /* width: 50%; */
  /* margin: 0px 24%; */
  /* margin: 0px 180px 0px 180px; */
  /* position: relative; */
}
.infobox-content{
  display: flex;
  padding-left: 20px;
  padding-bottom: 20px;
}
.infobox-content table{
  table-layout: fixed;
  width: 100%;
}
.infobox td{
  padding-right: 20px;
  word-wrap:break-word
}
.opportunity-container img.badge{
  position: absolute;
  width: 160px;
  height: auto;
  top: -120px;
  right: 0;
}

.content-flex {
  display:flex;
  flex-direction: column-reverse;
}

.content-right {
  padding: 20px;
}

@media only screen and (min-width:320px) {
  .content-right {
    width: 320px;
  }
}

@media only screen and (min-width:768px) {
  .content-right {
    width: 500px;
    align-self: center;
  }
}

@media only screen and (min-width:1024px) {
  .content-flex {
    flex-direction: row
  }
  .content-left {
    width: 400px;
  }

  .content-right {
    align-self: flex-start;
  }
}

/* .opportunity-container .content-left{
  width: 50%;
}
.opportunity-container .content-right{
  width: 50%;
  padding: 0px 20px 0px 20px;
} */
.privacycontent{
  padding: 0px 20px 0px 20px;
}
#stickybox {
  /* Position absolutely, 30px down from the top */
  /* position: absolute;
  top: 30px; */

  /* In my case I'm centering it in the window; do what you like */
  /* margin-left: -100px;
  left: 50%;
  width: 200px; */

  /* These are just for my example */
  /* height: 1.25em;
  border: 1px solid #bbb;
  text-align: center; */
}
.confirm-delete{
  text-align: center;
  /* overflow: scroll; */
}
.confirm-delete .content{
  display: flex;
}
.confirm-delete button{
  border-radius: 8px;
  color: #FFFFFF !important;
  border: 2px solid #00AACC;
  background-color: #00AACC;
  cursor: pointer;
  font-size: 20px;
}
.confirm-delete .content-left{
  width: 50%;
  margin-right: 20px;
}
.confirm-delete .content-left button{
  float: right;
}
.confirm-delete .content-right{
  width: 50%;
  margin-left: 20px;
}
.confirm-delete .content-right button{
  float: left;
}
.opp-list-page p{
  /* text-align: center; */
}
.opportunity-page-warning{
  color: red;
  position: fixed;
  background: white;
  width: 100%;
  z-index: 5;
  opacity: 0.9;
  padding: 0px 20px 0px 20px;
}
.nav-dropdown-ext{
  border-top: 2px dotted #ddd;
  margin-top: 5px;
  padding-top: 1px;
}
.crop-opp-img{
  max-height: 200px;
  overflow: hidden;
  margin: -15px -15px 0px -15px;
  position: relative;
}
.crop-opp-img:after {
  content: '';
  position: absolute;
  top: 0; right: 0;
  bottom: 0; left: 0;
  background: -webkit-gradient(linear,left top, left bottom,from(rgba(100,100,100,.03)),to(rgb(100, 100, 100, 0.20)));
  background: -webkit-linear-gradient(top,rgba(100,100,100,.03),rgb(100, 100, 100, 0.20));
  background: linear-gradient(180deg,rgba(100,100,100,.03),rgb(100, 100, 100, 0.20));
}
.crop-opp-img img{
  width: 100%;
}

.participants {
  padding: 0px 20px 0px 20px;
  border-radius: 20px;
  overflow-x: auto;
  font-size: 14px;
}
.participants table{
  width:100%;
  display: inline-block;
  table-layout: fixed;
  border-collapse: collapse;
}
.participants tr:nth-child(even) {
  background-color: #f2f2f2;
}
.participants th {
  background-color: #00AACC;
  color: white;
  height: 36px;
}
.participants th, .participants td{
  padding: 5px 20px 5px 20px;
  overflow: hidden;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  text-align: left;
}
.participants button {
  margin: 2px;
  border-radius: 8px;
  color: #FFFFFF !important;
  border: 2px solid #00AACC;
  background-color: #00AACC;
  cursor: pointer;
  font-weight: 700;
  font-size: 12px;
}
.participants .table-el{
  height: 30px;
  white-space:nowrap;
  overflow: hidden;
}
.register-form-content
{
  background-color:#00AACC;
  min-height: calc(100vh - 100px);
}

.register-form input
{
	border-width:0px;
	outline:none;
  font-weight:bold;
  /* color:#707070; */
}

.register-form .b3
{
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-o-border-radius: 3px;
  -ms-border-radius: 3px;
  background-color: #F7B333;
  box-shadow: none;
}

.register-form .tb
{
	display:table;
}

@media screen and (min-width: 375px) {
  .tb {
    width: 343px;
  }
}

.register-form .td
{
	display:table-cell;
	vertical-align:middle;
}

.register-form .material-icons
{
	color:#bbb;
	vertical-align:middle;
}

.register-form .icon
{
	width:24px;
  padding:10px;
  color: #ddd;
}

.register-form .mrg3b
{
	margin-bottom:0px;
}

.register-form .mrg25t
{
  margin-top: 1rem;
  margin-bottom: 2.5vh;
}

.register-form
{
	font-size:16px;
	width:343px;
	margin:0 auto;
  padding-top: 1rem;
	font-weight:bold;
}

.register-form .cl-wh
{
	color:#fff;
}

#f-mlb
{
	text-align:center;
	font-size: 26px;
	margin-bottom: 2rem;
}

.register-form .f-i-bx
{
  margin-top: 7px;
  background-color: #fff;
	overflow:hidden;
}

.register-form .f-i-bx input
{
	width:100%;
	height:24px;
  font-size: 13.333333px;
  color: #707070;
	background-color:transparent;
	padding:0px;
}

.register-form .prt
{
	padding-right:10px;
}

.register-form form label
{
	padding-left:3px;
}

#tc-bx
{
    margin-top: 25px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    font-weight: normal;
}

#tc-bx a
{
  color: #fff;
  font-weight: bold; 
  text-decoration:none;
}

.register-form .f-i-bx,#s-btn input
{
	box-shadow: 0px 4px 4px #3f3f3f;
	-webkit-box-shadow: 0px 4px 4px #3f3f3f;
	-moz-box-shadow: 0px 4px 4px #3f3f3f;
	-o-box-shadow: 0px 4px 4px #3f3f3f;
	-ms-box-shadow: 0px 4px 4px #3f3f3f;
}

#s-btn input
{
  padding: 10px;
  width: 100%;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  background-color: #F7B333;
  box-shadow: none;
  /* margin-bottom: 2.5vh; */
}

#s-btn input:active
{
	position:relative;
	top:3px;

}
#s-btn input:disabled
{
	position:relative;
  top:3px;
  color:#ddd;
	background: -webkit-gradient(linear, left top, left bottom, from(#bbb), to(#707070));
	background: linear-gradient(#bbb, #707070);
	background: -webkit-linear-gradient(#bbb, #707070);
	background: -ms-linear-gradient(#bbb, #707070);
}

.text__clickable-link {
  color: white;
  text-align: center;
}

.text__clickable-link > a {
  color: #F7B333;
  font-weight: 600;
  font-size: 18px;
}
.register-form .form-group{
  margin-bottom: 25px;
}
#fourOhFour{
  background: url(http://www.reactiongifs.us/wp-content/uploads/2015/04/nothing_to_see_here_naked_gun.gif);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 75vh;
  color: white;
}
#fourOhFour h1{
  color: white;
  font-size: 160px;
  margin-bottom: 0;
  margin-top: 0;
}
#fourOhFour h2{
  color: white;
  margin-top: 0;
  max-width: 700px;
  font-size: 30px;
  width: 90%;
}
#fourOhFour p{
  color: white;
  text-align: left;
  padding-bottom: 32px;
  
}
#fourOhFour a{
  color: white;
  text-decoration: underline;
}
#fourOhFour .container{
  max-width: 100%;
  background-color: black;
  opacity: 0.6;
}
.login-page{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15vh;
  padding-bottom: 15vh;
}

@media screen and (min-width: 375px) {
  .login-page {
    width: 343px;
    padding: 20vh 0px 0px 0px;
    align-items: center;
  }
}

.clear, article.post:after {
  clear: both;
  content: "";
  display: block;
}
article.post {
  margin-bottom: 10px;
  padding: 0 0 10px 110px;
  color:#707070;
}
article.post:last-of-type {
  border-bottom: 0;
  padding-bottom: 0;
}
/* article.post img {
  float: left;
  margin-left: -110px;
  max-height: 20vh;
  height:100%;
  width: auto;
  max-width: 20vw;
} */
.crop-news-img{
  float: left;
  margin: -5px 10px -15px -120px;
  /* margin-left: -110px; */
  padding:0px;
  height:20vh;
  width:17vw;
  max-height: 20vh;
  max-width: 20vw;
  overflow: hidden;
  /* margin: -15px -15px 0px -15px; */
  position: relative;
}
/* .crop-news-img:after {
  content: '';
  position: absolute;
  top: 0; right: 0;
  bottom: 0; left: 0;
  background: linear-gradient(180deg,rgba(100,100,100,.03),rgb(100, 100, 100, 0.20));
} */
.crop-news-img img{
  margin:0;
  padding:0;
  width: auto;
  /* min-width: 100%; */
  /* min-height: 100%; */
  height: 100%;
}
.crop-news-img::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: -webkit-gradient(linear,left top, right top,from(rgba(255, 255, 255, 0.03)),to(rgb(100, 100, 100, 0.20)));
  background: -webkit-linear-gradient(left,rgba(255, 255, 255, 0.03),rgb(100, 100, 100, 0.20));
  background: linear-gradient(90deg,rgba(255, 255, 255, 0.03),rgb(100, 100, 100, 0.20));
}
article.post h1 {
  font-size: 26px;
  font-weight: normal;
  margin: 0;
}
article.post a {
  color: #dc4e00;
  text-decoration: none;
}
article.post time {
  display: block;
  font-size: 11px;
  font-weight: bold;
}
article.post small {
  display: block;
  font-size: 11px;
  font-weight: bold;
}
.news-item{
  background-color: white;
  margin: 10px;
  padding: 5px;
  border-radius: 5px;
  position: relative;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.2);
    -webkit-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  -webkit-transition: -webkit-transform .2s ease-in-out;
  transition: -webkit-transform .2s ease-in-out;
  transition: transform .2s ease-in-out;
  transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
}
.news-item:hover {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25), 0 5px 8px rgba(0, 0, 0, 0.22);
  cursor: pointer;
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}
/* .news-item:hover img.news-img{
  transform: scale(1.20);
  transition: transform .2s;
} */
.news-items-content {
  background-color: rgb(248, 248, 248);
  width: 100%;
}
.ervaring-gradient:after{
  content: '';
  position: absolute;
  top: 0; right: 0;
  bottom: 0; left: 0;
  background: -webkit-gradient(linear,left top, left bottom,from(rgba(100,100,100,.03)),to(rgb(100, 100, 100, 0.10)));
  background: -webkit-linear-gradient(top,rgba(100,100,100,.03),rgb(100, 100, 100, 0.10));
  background: linear-gradient(180deg,rgba(100,100,100,.03),rgb(100, 100, 100, 0.10));
}
#opportunities{
  background: rgb(248, 248, 248);
}
/* #recente-ervaringen{
  background: rgb(248, 248, 248);
} */
.create_opportunity .content-left{
  width: 55%;
  padding-right: 20px;
}
.create_opportunity .content-right{
  padding-left: 20px;
  width: 45%;
  color: #707070;
  text-align: center;
}
.example_opportunity_wrapper{
  border-radius: 40px;
  box-shadow: 0px 3px 10px 0px rgba(50, 50, 50, 0.5);
  overflow: hidden;
}
.example_opportunity img{
  width:100%;
  height:auto;
}
.content-create-opp{
  padding-left: 80px;
  padding-right: 20px;
}

.backpack-item label p{
  min-height: 5rem;
}

.backpack-item{
  width: 60vw;
  padding: 10px;
  text-align: center;
  min-width: 1rem;
}
  @media screen and (min-width: 360px) {
    .backpack-item{
      width: 35vw;
    }
  }
  @media screen and (min-width: 550px) {
    .backpack-item{
      width: 25vw;
    }
  }
  @media screen and (min-width: 700px) {
    .backpack-item{
      width: 18vw;
    }
  }
  @media screen and (min-width: 900px) {
    .backpack-item{
      width: 15vw;
    }
  }
  @media screen and (min-width: 1080px) {
    .backpack-item{
      width: 135px;
    }
  }
.backpack-item img{
  width: 100%;
  height: auto;
}
.backpack{
  display: flex;
  flex-wrap: wrap;
}
.download-badge{
  display: inline-block;
  border-radius: 8px;
  color: #FFFFFF !important;
  border: 2px solid #00AACC;
  background-color: #00AACC;
  margin-bottom: 0px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  font-weight: 700;
}
.download-badge:hover {
  cursor: pointer;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  color: #707070 !important;
  background-color: white; }
.opp-detail-option{
  display: inline-block;
  font-size: 14px;
  border-radius: 8px;
  color: #707070 !important;
  border: 1px solid #707070;
  background-color: white;
  margin-bottom: 0px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  /* font-weight: 700; */
}
.opp-detail-option:hover{
  cursor: pointer;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  color: white !important;
  background-color: #707070; }

.opp-save{
  display:block; /* change this from inline-block */
  /* width:20%; */
  margin:0 auto;
  padding: 10px;
  width: 35vw;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  background: -webkit-gradient(linear, left top, left bottom, from(rgb(0, 200, 240)), to(rgb(0, 128, 153)));
  background: linear-gradient(rgb(0, 200, 240), rgb(0, 128, 153));
  background: -webkit-linear-gradient(rgb(0, 200, 240), rgb(0, 128, 153));
  background: -ms-linear-gradient(rgb(0, 200, 240), rgb(0, 128, 153));
  box-shadow: 0px 4px 4px #3f3f3f;
  -webkit-box-shadow: 0px 4px 4px #3f3f3f;
  -moz-box-shadow: 0px 4px 4px #3f3f3f;
  -o-box-shadow: 0px 4px 4px #3f3f3f;
  -ms-box-shadow: 0px 4px 4px #3f3f3f;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -o-border-radius: 3px;
  -ms-border-radius: 3px;
  border-width: 0px;
  outline: none;
  font-weight: bold;
}
.scroll-down-arrow{
  position: absolute;
  bottom: 20px;
  left: 50%;
  z-index: 2;
  display: inline-block;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  color: #fff;
  font: normal 400 20px/1 'Josefin Sans', sans-serif;
  letter-spacing: .1em;
  text-decoration: none;
  -webkit-transition: opacity .3s;
  transition: opacity .3s;
}
.scroll-down-arrow:hover {
  opacity: .5;
}
.scroll-down-arrow span{
  padding: 15px;
  position: absolute;
  top: 0;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  box-sizing: border-box;
}
#anchor {
  padding-top: 20vh;
  margin-top: -20vh;
}
#page{
  max-width: 70vw;
  margin-left: auto !important;
  margin-right: auto !important;
}
@media screen and (min-width: 1400px) {
  #page{
    max-width: 1140px;
  }
}
.news-item-content p{
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.news-item-content small{
  padding-left: 40px;
  padding-right: 20px;
}
.submit-padding{
  padding-bottom: 80px;
}

.introductory-video{
  padding: 0px;
  margin-top: 5vh;
  margin-bottom: 10px;
  /* border: 1px solid #464646; */
}

#startpage .text{
  display: none;
}

.list-opportunities {
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
}

/* @media screen and (min-width: 1080px) {
  #startpage .text{
    display: none;
  }
}
@media screen and (max-width: 1080px) {
  .introductory-video{
    display: none;
  } 
} */
.leaflet-container {
  z-index: 998;
}

.leaflet-overlay-pane path,
.leaflet-marker-icon {
  cursor: pointer;
}

.leaflet-dragging .leaflet-grab {cursor: move;}

#map {
  width: 100%;
  height: 500px;
}

#map:hover, #map-2 {
  cursor: -webkit-grab;
  cursor: grab
}

#map-2 {
  width: 100%;
  height: 400px
}

#map-2 .leaflet-marker-icon,
#map-2 .leaflet-overlay-pane path {
  cursor: move;
}

.my-opportunity {
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

.my-opportunity:hover{
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}

.quests-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

@media only screen and (min-width:1024px) {

  .quests-content {
    flex-direction: row-reverse;
  }

  .quests-content .content-left {
    width: 60vw;
  }
  
  .quests-content .content-right {
    position: -webkit-sticky;
    position: sticky;
    top: 6.5rem;
    width: 40vw;
  }
}

.news-item .post {
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.quest-logo {
  min-width: 5rem;
  min-height: 5rem;
  max-width: 5rem;
  max-height: 5rem;
  padding: 1.5rem;
}

.questlist-container ul {
  list-style-type: none;
  padding: 0;
}

.questlist-container {
  display: block;
}

.sc-bxivhb .dYhqhW {
  display: flex;
  width: 100vw;
}

.quest-info {
  color: #1BA9C9;
  font-weight: bold;
  padding: 1rem;
  border: solid 0.1rem #1BA9C9;
  border-radius: .5rem;
}

.quest-info tr {
  line-height: 150%;
}

.quest-form {
  width: 100vw;
  height: 100%;
  display: grid;
  grid-template-columns: 8px 1fr 200px 1fr 8px;
  grid-template-rows: 16px 40vh 30px 40vh 30px 1fr 30px;
  grid-template-areas: 
  ". . . . ."
  ". form form form ."
  ". . . . ."
  ". map map map ."
  ". . . . ."
  ". . submit . .";
}

@media screen and (min-width: 1024px) {
  .quest-form {
    grid-template-columns: 16px 100px 200px 100px 50px 1fr 16px;
  grid-template-rows: 5vw 40vh 50px 1fr 5vw;
  grid-template-areas: 
  ". . . . . . ."
  ". form form form . map ."
  ". form form form . . ."
  ". . submit . . . ."
  ". . . . . . .";
  }

  .coordinates {
    flex-direction: row;
    justify-content: space-evenly;
  }

}

@media screen and (min-width: 1440px) {
  .quest-form {
    grid-template-columns: 16px 200px 200px 200px 50px 1fr 16px;
  grid-template-rows: 5vw 60% 50px 40px 5vw;
  grid-template-areas: 
  ". . . . . . ."
  ". form form form . map ."
  ". form form form . map ."
  ". . submit . . . ."
  ". . . . . . .";
  }
}

.quest-form__group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
}

.quest-form__left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-area: form;
}

.quest-form__right {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: map;
}

.submit {
  margin-top: 0;
  height: 100%;
  grid-area: submit;
}

.quest-form__input {
  display: block;
  padding: 8px 16px;
  margin-bottom: 8px;
  width: calc(100% - 32px - 2px);
  border: 1px solid #ddd;
  border-radius: 8px;
  outline: none;
}

.quest-form__label {
  display: block;
  width: 100%;
  padding: 8px 0 0;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  font-weight: bold;
  color: #707070;
  padding-bottom: 2px; 
}

.coordinates {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-evenly;
}

.input-coordinates {
  width: 200px;
  border: none;
  border-bottom: 1px solid #ddd;
  border-radius: 0px;
  font-size: 16px;
}
